import React, { useCallback, useEffect, useState } from 'react';
import {
    Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, InputLabel, LinearProgress, TextField, Typography,
    Button, FormControl, Select, MenuItem, Box, Pagination, CircularProgress, Paper
} from '@mui/material';
import dashboardImage from "../../assets/images/dashboard.svg";
import receivedOrders from "../../assets/images/receivedOrders.svg";
import pendingOrders from "../../assets/images/pendingOrders.svg";
import assignTestToLab from "../../assets/images/assignTestToLab.svg";
import CustomDateTimePicker from '../common/customdatetimepicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import apiService from '../../services/actions';
import dayjs from 'dayjs';
import { formatDateToMMDDYYYY, formateDateToDDMMYYYYHHMM } from '../common';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes/approutes';
import CustomDatePicker from '../common/customdatepicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import LabOrdersLineChart from '../common/laborderlinechart';
import SLAComplianceBarChart from '../common/slacompliancebarchart';

const Dashboard = () => {

    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(-1);
    const [orderDetails, setOrderDetails] = useState([]);
    const [hospitalId, setHospitalId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [page, setPage] = useState(1);
    const [b2bCount, setB2B] = useState(0);
    const [b2B2c, setB2B2c] = useState(0);
    const [failureCount, setFaiureCount] = useState(0);
    const [bookingCount, setbookingCount] = useState(0);
    const [reportCount, setReportCount] = useState(0);
    const [cardData, setcardData] = useState([]);
    const [bussinessCard, setbussinessCard] = useState([]);
    const [parntnerCard, setpartnerCard] = useState([]);

    const [labpartnerData, setLabPartnerData] = useState([]);


    const [receivedOrdersLoading, setReceiedOrdersLoading] = useState(true)
    const itemsPerPage = 5;
    const [fromDate, setFromDate] = useState(dayjs().subtract(7, 'day'));
    const [toDate, setToDate] = useState(dayjs());
    const [loading, setLoading] = useState(false);
    const [confirmationStatus, setConfirmationStatus] = useState(true);
    const [count, setCount] = useState({ completedOrder: 0, pendingOrder: 0, receivedOrder: 0 });
    const completedOrderPercentage = count.receivedOrder > 0 ? Math.round((count.completedOrder / count.receivedOrder) * 100) : 0;
    const pendingOrderPercentage = count.receivedOrder > 0 ? Math.round((count.pendingOrder / count.receivedOrder) * 100) : 0;
    const [formData, setFormData] = useState([
        {
            homeSampleCollectionDateTime: '',
            labTestDateTime: '',
            techniciansAssigned: '',
            contactNumber: '',
            status: '',
        }
    ]);
    const [selectedRange, setSelectedRange] = useState(1);
    const [showSecondDropdown, setShowSecondDropdown] = useState(false);
    const [secondDropdownOptions, setSecondDropdownOptions] = useState([]);
    const [labOrderGraphDetails, setLabOrderGraphDetails] = useState([]);
    const [selectedRangeOption, setSelectedRangeOption] = useState('');
    const [selectedSLARange, setSelectedSLARange] = useState(1);
    const [showSecondSLADropdown, setShowSecondSLADropdown] = useState(false);
    const [secondSLADropdownOptions, setSecondSLADropdownOptions] = useState([]);
    const [slaComplianceGraphDetails, setSLAComplianceGraphDetails] = useState([]);
    const [selectedSLARangeOption, setSelectedSLARangeOption] = useState('');
    const [activeTest, setactiveTest] = useState();


    const handleFieldChange = (recordIndex, field, value) => {
        setFormData((prevData) => {
            const newData = [...prevData];
            if (field === 'contactNumber') {
                const numericValue = value.replace(/\D/g, '');
                const maxLength = 10;
                newData[recordIndex] = {
                    ...newData[recordIndex],
                    [field]: numericValue.slice(0, maxLength),
                };
            } else {
                newData[recordIndex] = {
                    ...newData[recordIndex],
                    [field]: value,
                };
            }

            return newData;
        });
    };

    const parseLocationData = (locationData) => {
        try {
            if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
            } else {
                console.error('Location data is empty or undefined.');
            }
        } catch (error) {
            console.error('Error parsing location data:', error);
        }
    };


    useEffect(() => {
        const locationData = localStorage.location;
        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            parseLocationData(locationData);
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }
    }, []);

    useEffect(() => {
        const fetchCountData = async () => {
            let hospitalIdlocalCopy = null;
            let locationIdlocalCopy = null;
            const locationData = localStorage?.location;
            if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
                try {
                    const data = JSON.parse(locationData);
                    const { hospitalId, locationId } = data;
                    setHospitalId(hospitalId);
                    setLocationId(locationId);
                    hospitalIdlocalCopy = hospitalId;
                    locationIdlocalCopy = locationId;
                } catch (error) {
                    console.error('Error parsing location data:', error);
                }
            } else {
                console.log('No data found in localStorage.location or the data is empty.');
            }
            try {
                const payload = {
                    hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
                    locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
                    fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                    toDate: dayjs(toDate).format('YYYY-MM-DD')

                };
                setLoading(true)
                const countDetailsResponse = await apiService.getCountDetails(payload);
                setLoading(false)

                if (countDetailsResponse.status === 200 && countDetailsResponse?.data) {
                    setCount(countDetailsResponse.data?.[0]);

                } else {
                    console.error('API error:', countDetailsResponse.statusText);
                }
            } catch (error) {
                console.error('API request error:', error);
            }
        };

        fetchCountData();
    }, [fromDate, toDate, hospitalId, locationId]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getDataForPage = useCallback((page) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const dataForPage = orderDetails.slice(startIndex, endIndex);
        return dataForPage;
    }, [orderDetails, itemsPerPage]);

    useEffect(() => {
        const fetchData = async () => {
            let hospitalIdlocalCopy = null;
            let locationIdlocalCopy = null;
            const locationData = localStorage?.location;
            if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
                try {
                    const data = JSON.parse(locationData);
                    const { hospitalId, locationId } = data;
                    setHospitalId(hospitalId);
                    setLocationId(locationId);
                    hospitalIdlocalCopy = hospitalId;
                    locationIdlocalCopy = locationId;
                } catch (error) {
                    console.error('Error parsing location data:', error);
                }
            } else {
                console.log('No data found in localStorage.location or the data is empty.');
            }
            try {
                if (confirmationStatus) {
                    const payload = {
                        procedureStatus: 0,
                        fromDate1: formatDateToMMDDYYYY(dayjs().subtract(1, 'day')),
                        toDate1: formatDateToMMDDYYYY(dayjs()),
                        hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
                        locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
                        status: 1,
                        orderDetailsRequired: 'Y',
                    };
                    setLoading(true)

                    const ordersDetailsResponse = await apiService.getOrderDetails(payload);
                    setLoading(false)

                    if (ordersDetailsResponse.status === 200 && ordersDetailsResponse?.data) {
                        setOrderDetails(ordersDetailsResponse.data);
                        setConfirmationStatus(false);
                    } else {
                        console.error('API error:', ordersDetailsResponse.statusText);
                    }
                }
            } catch (error) {
                console.error('API request error:', error);
            } finally {
                setReceiedOrdersLoading(false);
            }
        };

        fetchData();
    }, [hospitalId, locationId, confirmationStatus]);


    const handleExpandClick = (index, data) => {
        setactiveTest(data)
        console.log(data)
        setExpanded(expanded === index ? -1 : index);
        let payload = {
            homeSampleCollectionDateTime: data.preferDateTime ? data.preferDateTime : '',
            labTestDateTime: data.collectionSampleDate ? data.collectionSampleDate : '',
        }
        updateObjectAtIndex(index, payload)
    };
    const updateObjectAtIndex = (index, updatedObject) => {
        setFormData((prevArray) => {
            // Create a copy of the array to avoid mutating state directly
            const newArray = [...prevArray];
            newArray[index] = updatedObject;
            return newArray;
        });
    };


    const confirmOrder = async (orderDetails) => {
        setLoading(true);
        try {
            const recordIndex = getDataForPage(page).findIndex((record) => record.orderId === orderDetails.orderId);
            if (!formData[recordIndex]?.status) {
                setLoading(false);

                return toast.error("Please select the Status")
            }
            if (recordIndex !== -1) {
                const updatedOrderDetails = {
                    ...orderDetails,
                    procedureStatus: 1,
                    technicianAssigned: formData[recordIndex]?.techniciansAssigned,
                    technicianContactNo: formData[recordIndex]?.contactNumber,
                    confirmDateTime: moment(formData[recordIndex]?.confirmDateTime)?.format("YYYY-MM-DD HH:mm:ss"),
                    collectionSampleDate: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    preferDateTime: formData[recordIndex]?.labTestDateTime ? moment(formData[recordIndex]?.labTestDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.collectionSampleDate ? moment(activeTest.collectionSampleDate)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    testLabDateTime: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    testLabDateTime1: formData[recordIndex]?.homeSampleCollectionDateTime ? moment(formData[recordIndex]?.homeSampleCollectionDateTime)?.format("YYYY-MM-DD HH:mm:ss") : activeTest?.preferDateTime ? moment(activeTest.preferDateTime)?.format("YYYY-MM-DD HH:mm:ss") : '',
                    collectionStatus: formData[recordIndex]?.status,
                };
                setLoading(true)

                const confirmOrderResponse = await apiService.patientLabTestsOrder(updatedOrderDetails, 'PUT');
                setLoading(false);

                if (confirmOrderResponse?.status === 200 && confirmOrderResponse?.data) {
                    if (confirmOrderResponse.data) {
                        toast.success("Order Confirmed successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        setConfirmationStatus(true);
                        setOrderDetails([]);
                    } else {
                        toast.error("Please try after sometime", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }
            } else {
                setLoading(false);
                toast.error("Order details not found.", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error confirming order:", error);
        }
    };

    const getMonthOptions = () => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months.map((month, index) => (
            <MenuItem key={index} value={index + 1}>{month}</MenuItem>
        ));
    };

    const getYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 8 }, (_, index) => currentYear - index);
        return years.map((year) => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
        ));
    };

    useEffect(() => {
        let hospitalIdlocalCopy = null;
        let locationIdlocalCopy = null;
        const locationData = localStorage?.location;

        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            try {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
                hospitalIdlocalCopy = hospitalId;
                locationIdlocalCopy = locationId;
            } catch (error) {
                console.error('Error parsing location data:', error);
            }
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }

        if (selectedRange === 1) {
            setSecondDropdownOptions([]);
            setSelectedRangeOption('')
        } else if (selectedRange === 2) {
            setSecondDropdownOptions(getMonthOptions());
            setSelectedRangeOption('')
        } else {
            setSecondDropdownOptions(getYearOptions());
            setSelectedRangeOption('')
        }

        const fetchDetails = async () => {
            if ((selectedRange === 2 || selectedRange === 3) && !selectedRangeOption) {
                return;
            }

            const formatDate = (year, month, day) => {
                return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            };

            let payload = {
                rangeType: selectedRange === 1 ? 'Week' : selectedRange === 2 ? 'Month' : selectedRange === 3 ? 'Year' : 'Week',
                hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
                locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
            };

            if (selectedRange === 2) {
                const currentYear = new Date().getFullYear();
                const selectedMonth = selectedRangeOption;
                const rangeDate = formatDate(currentYear, selectedMonth, 1);
                payload.rangeDate = rangeDate;
            } else if (selectedRange === 3) {
                const selectedYear = selectedRangeOption;
                const rangeDate = formatDate(selectedYear, 1, 1);
                payload.rangeDate = rangeDate;
            }
            setLoading(true)

            const labOrderGraphDetails = await apiService.getLabOrderGraphDetails(payload);
            setLoading(false)

            if (labOrderGraphDetails?.status === 200 && labOrderGraphDetails?.data) {
                setLabOrderGraphDetails(labOrderGraphDetails.data);
            }
        };

        fetchDetails();
    }, [selectedRange, hospitalId, locationId, selectedRangeOption]);

    useEffect(() => {
        let hospitalIdlocalCopy = null;
        let locationIdlocalCopy = null;
        const locationData = localStorage?.location;

        if (locationData && localStorage?.userRole !== 'DIAGGROUPADMIN') {
            try {
                const data = JSON.parse(locationData);
                const { hospitalId, locationId } = data;
                setHospitalId(hospitalId);
                setLocationId(locationId);
                hospitalIdlocalCopy = hospitalId;
                locationIdlocalCopy = locationId;
            } catch (error) {
                console.error('Error parsing location data:', error);
            }
        } else {
            console.log('No data found in localStorage.location or the data is empty.');
        }

        if (selectedSLARange === 1) {
            setSecondSLADropdownOptions([]);
            setSelectedSLARangeOption('')
        } else if (selectedSLARange === 2) {
            setSecondSLADropdownOptions(getMonthOptions());
            setSelectedSLARangeOption('')
        } else {
            setSecondSLADropdownOptions(getYearOptions());
            setSelectedSLARangeOption('')
        }

        const fetchDetails = async () => {
            if ((selectedSLARange === 2 || selectedSLARange === 3) && !selectedSLARangeOption) {
                return;
            }

            const formatDate = (year, month, day) => {
                return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            };

            let payload = {
                rangeType: selectedSLARange === 1 ? 'Week' : selectedSLARange === 2 ? 'Month' : selectedSLARange === 3 ? 'Year' : 'Week',
                hospitalId: hospitalIdlocalCopy ? hospitalIdlocalCopy : hospitalId ? hospitalId : undefined,
                locationId: locationIdlocalCopy ? locationIdlocalCopy : locationId ? locationId : undefined,
            };

            if (selectedSLARange === 2) {
                const currentYear = new Date().getFullYear();
                const selectedMonth = selectedSLARangeOption;
                const rangeDate = formatDate(currentYear, selectedMonth, 1);
                payload.rangeDate = rangeDate;
            } else if (selectedSLARange === 3) {
                const selectedYear = selectedSLARangeOption;
                const rangeDate = formatDate(selectedYear, 1, 1);
                payload.rangeDate = rangeDate;
            }
            setLoading(true)

            const slaComplianceGraphDetails = await apiService.getSLAComplianceGraphDetails(payload);
            setLoading(false)

            if (slaComplianceGraphDetails?.status === 200 && slaComplianceGraphDetails?.data) {
                setSLAComplianceGraphDetails(slaComplianceGraphDetails.data);
            }
        };

        fetchDetails();
    }, [selectedSLARange, hospitalId, locationId, selectedSLARangeOption]);


    const handleRangeChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedRange(selectedValue);
        setShowSecondDropdown(selectedValue !== 1);
    };

    const handleRangeOptions = (event) => {
        setSelectedRangeOption(event.target.value);
    };

    const handleSLARangeChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSLARange(selectedValue);
        setShowSecondSLADropdown(selectedValue !== 1);
    };

    const handleSLARangeOption = (event) => {
        setSelectedSLARangeOption(event.target.value);
    };

    const handleCardClick = (data) => {
        if (data.title === 'Received orders') {
            // navigate(APP_ROUTES.RECEIVEDORDERS);
        } else if (data.title === 'Completed orders') {
            // navigate(APP_ROUTES.COMPLETEDORDERS);
        } else {
            // navigate(APP_ROUTES.PENDINGORDERS)
        }
    };
    const handleCardClick3 = (data) => {
        if(data){
            let filter ={
                fromDate :  dayjs(fromDate),
                toDate :  dayjs(toDate)
            }
            if(data.title === 'Booking ID'){
                filter.createBookingFailure = 1

            }
            else{
                filter.reportFailure = 1

            }
            //  navigate(APP_ROUTES.PENDINGORDERS,{ state:filter  }) 
    }
    };
    const handleCardClick2 = (data,n) => {
        // if (data.title === 'Received orders') {
        //     navigate(APP_ROUTES.RECEIVEDORDERS);
        // } else if (data.title === 'Completed orders') {
        //     navigate(APP_ROUTES.COMPLETEDORDERS);
        // } 
        // else {
if((n == 1 && data.pendingCount == 0 )||( n==2 && data.completedCount ==0 )) {
return
}
            if(data){
                let filter ={
                    labName:  data.labPartner,
                    procedureStatus: -2,
                    uploadStatus: n,
                    fromDate :  dayjs(fromDate),
                    toDate :  dayjs(toDate)

                }
                
        //    n == 1 ? navigate(APP_ROUTES.PENDINGORDERS,{ state:filter  }) :  navigate(APP_ROUTES.COMPLETEDORDERS, { state:filter  });
        }
        // }
    };
    const handleCardClick1 = (data) => {
        // if (data.title === 'B2B') {
        if(data){
            // navigate(APP_ROUTES.LABLIST, { state:data.title  });
        }
        // } else if (data.title === 'B2B2C') {
        //     navigate(APP_ROUTES.COMPLETEDORDERS);
        // } else {
        //     navigate(APP_ROUTES.PENDINGORDERS)
        // }
    };


    const getData = async () => {
        let payLoad ={   fromDate: dayjs(fromDate).format('MM/DD/YYYY'),
            toDate: dayjs(toDate).format('MM/DD/YYYY')}
        try {
            setLoading(true)

            const [b2bRes, failureRes, reportRes,labpartner] = await Promise.all([
                apiService.b2bCount(),
                apiService.failureBooking(payLoad),
                apiService.reportFailure(payLoad),
                apiService.labPartnerCount(payLoad)

            ]);
            setLoading(false)

            if (b2bRes.data) {
                setB2B(b2bRes.data.b2bCount);
                setB2B2c(b2bRes.data.b2cCount);
            } else {
                toast.error("Please try after sometime");
            }

            if (failureRes.data) {
                setFaiureCount(failureRes.data.count);
            } else {
                toast.error("Please try after sometime");
            }

            if (reportRes.data) {
                setbookingCount(reportRes.data.count);
            } else {
                toast.error("Please try after sometime");
            }
            if(labpartner.data){
                setLabPartnerData(labpartner.data)
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
        }
    };

    useEffect(() => {
        getData();

        const timer = setTimeout(() => {
            setUpcards();
        }, 10);

        return () => clearTimeout(timer); // Cleanup timeout on unmount
    }, [b2bCount, b2B2c, failureCount, bookingCount, reportCount, count,fromDate]); // Dependencies list

    const setUpcards = () => {
        let data = [
            // { title: "Received orders", percentage: 100, image: receivedOrders, count: count.receivedOrder },
            // { title: "Completed orders", percentage: completedOrderPercentage, image: assignTestToLab, count: count.completedOrder },
            { title: " Orders", percentage: pendingOrderPercentage, image: pendingOrders, count: count.pendingOrder },

        ];
        let data2 =[            { title: "B2B", percentage: pendingOrderPercentage, image: pendingOrders, count: b2bCount },
            { title: "B2B2C", percentage: pendingOrderPercentage, image: pendingOrders, count: b2B2c },]

            let data3=[

                { title: "Booking ID", percentage: pendingOrderPercentage, image: pendingOrders, count:  failureCount },
                { title: "Reports", percentage: pendingOrderPercentage, image: pendingOrders, count: bookingCount }
            ]
        setcardData(data);
        setbussinessCard(data2)
        setpartnerCard(data3)
    };
    return (
        <>
            {/* <Card style={{ display: 'flex', marginTop: '2.5rem' }}>
                <CardContent style={{ flex: 1, margin: '2rem' }}>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{
                            fontFamily: 'Rubik',
                            fontSize: '36px',
                            fontWeight: 600,
                            lineHeight: '43px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            color: '#3D65FF',
                        }}
                    >
                        Hi, Welcome back
                    </Typography>
                    <div style={{ marginTop: '2rem' }}>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{
                                fontFamily: 'Rubik',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '29px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#262626',
                            }}
                        >
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever
                        </Typography>
                    </div>
                </CardContent>
                <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={dashboardImage} alt={'dashboard'} />
                </CardContent>
            </Card> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '3.5rem', maxWidth: '85%' }}>
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="secondary" />
                </div>
                <div style={{ textAlign: 'left' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
                        Dashboard
                    </Typography>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', maxWidth: '200px' }}>
                    <CustomDatePicker label="From Date" value={fromDate}
                        onChange={(dateTime) => setFromDate(dateTime)} />
                    <CustomDatePicker label="To Date" value={toDate}
                        onChange={(dateTime) => setToDate(dateTime)} />
                </div>
            </div>
            <div style={{ textAlign: 'left' ,marginTop:'20px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '18px' ,color:'blue'  }}>
                    Lab Partners
                    </Typography>
                </div>
            <div   style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',
    marginTop: '1rem',
  }}>
                   {labpartnerData.map((data, index) => (
                    <Card key={index}
                        style={{ flex: 1, marginRight: index !== cardData.length - 1 ? '1rem' : '0', position: 'relative' }}
                       >
                        <CardContent >
                        <div style={{ marginLeft: '1rem' }}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'left', color:'#42BA85'  }}>
                                    {data.labPartner}
                                </Typography>
                            </div>
                     
                       
                            <div  style={{marginTop:'10px'  }}>
                            <div  style={{ display: 'flex'}}>
                            <div style={{ marginLeft: '1rem' }}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'left'  , display: 'flex'  }}>
                                    Total Count --    
                                <Typography variant="h6" style={{ color: '#42BA85' , marginLeft: '5px' }}>
                                {'  '+ data.totalCount}
                                </Typography>
                                </Typography>
                            </div>
                            </div>
                            <div  style={{ display: 'flex'}}>
                            <div onClick={() => handleCardClick2(data ,1)}  style={{ marginLeft: '1rem' }}>
                                <Typography variant="h6" component="div"               sx={{ textAlign: 'left', display: 'flex', '&:hover': { color: 'blue' } }}
                                >
                                    Pending Count --    
                                <Typography variant="h6" style={{ color: '#42BA85' , marginLeft: '5px' }}>
                                    {data.pendingCount}
                                </Typography>
                                </Typography>
                            </div>
                            </div>
                            <div  style={{ display: 'flex' }}>
                            <div style={{ marginLeft: '1rem' }} onClick={() => handleCardClick2(data ,2)}>
                                <Typography variant="h6" component="div"                 sx={{ textAlign: 'left', display: 'flex', '&:hover': { color: 'blue' } }}
                                >
                                    Completed Count --
                                    <Typography variant="h6" style={{ color: '#42BA85' , marginLeft: '5px' }}>
                                    {data.completedCount}
                                </Typography>
                                </Typography>
                            </div>

                            </div>
                            </div>

                        </CardContent>
                    </Card>
                ))}
            </div>
            <div style={{ textAlign: 'left' ,marginTop:'20px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '18px',color:'blue' }}>
                        Orders Count
                    </Typography>
                </div>
            <div   style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',
    marginTop: '1rem',
  }}>
       
                {cardData.map((data, index) => (
                    <Card key={index}
                        style={{ flex: 1, marginRight: index !== cardData.length - 1 ? '1rem' : '0', position: 'relative' }}
                        onClick={() => handleCardClick(data)}>
                        <CardContent style={{ display: 'flex' }}>
                            <img src={data.image} alt={data.image} style={{ margin: 'auto 0', width: '50px' }} />
                            <div style={{ marginLeft: '1rem' }}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'left' }}>
                                    {data.title}
                                </Typography>
                                <LinearProgress variant="determinate" value={data.percentage}
                                    sx={{
                                        marginTop: '0.25rem',
                                        flexGrow: 1,
                                        height: '5px',
                                        width: '18rem',
                                        borderRadius: '3.5px',
                                        backgroundColor: '#ccc',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#42BA85',
                                        },
                                    }}
                                />
                                
                                <Typography style={{ textAlign: 'left', color: '#828282', fontSize: '14px', marginTop: '0.25rem' }}>
                                    {/* {data.percentage} Completed */}
                                </Typography>
                            </div>
                            <div   style={{
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    background: '#42BA85',
    padding: data.count.toString().length > 2 ? '0px' : '5px',
    borderRadius: '3.5px',
    width: '1.75rem',
    height: '2rem',
  }}>
                                <Typography style={{ color: '#FFFFFF' }}>
                                    {data.count}
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                ))}
                </div>

                         <div style={{ textAlign: 'left' ,marginTop:'20px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '18px' ,color:'blue'  }}>
                    Partner Failure Details
                    </Typography>
                </div>
                                <div   style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',
    marginTop: '1rem',
  }}>
                   {parntnerCard.map((data, index) => (
                    <Card key={index}
                        style={{ flex: 1, marginRight: index !== cardData.length - 1 ? '1rem' : '0', position: 'relative' }}
                        onClick={() => handleCardClick3(data)}>
                        <CardContent style={{ display: 'flex' }}>
                            <img src={data.image} alt={data.image} style={{ margin: 'auto 0', width: '50px' }} />
                            <div style={{ marginLeft: '1rem' }}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'left' }}>
                                    {data.title}
                                </Typography>
                                <LinearProgress variant="determinate" value={data.percentage}
                                    sx={{
                                        marginTop: '0.25rem',
                                        flexGrow: 1,
                                        height: '5px',
                                        width: '18rem',
                                        borderRadius: '3.5px',
                                        backgroundColor: '#ccc',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#42BA85',
                                        },
                                    }}
                                />
                                <Typography style={{ textAlign: 'left', color: '#828282', fontSize: '14px', marginTop: '0.25rem' }}>
                                    {/* {data.percentage} Completed */}
                                </Typography>
                            </div>
                            <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem', background: '#42BA85',     padding: data.count.toString().length > 2 ? '0px' : '5px',
 borderRadius: '3.5px', width: '1.75rem', height: '2rem' }}>
                                <Typography style={{ color: '#FFFFFF' }}>
                                    {data.count}
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
            <div style={{ textAlign: 'left' ,marginTop:'20px',color:'blue'  }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
                        BusinessType Count
                    </Typography>
                </div>
                <div   style={{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',
    marginTop: '1rem',
  }}>
                  {bussinessCard.map((data, index) => (
                    <Card key={index}
                        style={{ flex: 1, marginRight: index !== cardData.length - 1 ? '1rem' : '0', position: 'relative' }}
                        onClick={() => handleCardClick1(data)}>
                        <CardContent style={{ display: 'flex' }}>
                            <img src={data.image} alt={data.image} style={{ margin: 'auto 0', width: '50px' }} />
                            <div style={{ marginLeft: '1rem' }}>
                                <Typography variant="h6" component="div" style={{ textAlign: 'left' }}>
                                    {data.title}
                                </Typography>
                                <LinearProgress variant="determinate" value={data.percentage}
                                    sx={{
                                        marginTop: '0.25rem',
                                        flexGrow: 1,
                                        height: '5px',
                                        width: '18rem',
                                        borderRadius: '3.5px',
                                        backgroundColor: '#ccc',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#42BA85',
                                        },
                                    }}
                                />
                                <Typography style={{ textAlign: 'left', color: '#828282', fontSize: '14px', marginTop: '0.25rem' }}>
                                    {/* {data.percentage} Completed */}
                                </Typography>
                            </div>
                            <div style={{ position: 'absolute', top: '0.5rem', right: '0.5rem', background: '#42BA85',     padding: data.count.toString().length > 2 ? '0px' : '5px',
 borderRadius: '3.5px', width: '1.75rem', height: '2rem' }}>
                                <Typography style={{ color: '#FFFFFF' }}>
                                    {data.count}
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                ))}
                         </div>
            <div className="mt-4">
                <Grid container spacing={4}>
                    {/* Lab Orders */}
                    <Grid item xs={12} md={6}>
                        <Paper className="p-4 flex flex-col h-full">
                            <div className="flex justify-between items-center mb-4">
                                <Typography variant="h6" align="left">
                                    Lab Orders
                                </Typography>
                                <div className="flex items-center space-x-4">
                                    <FormControl variant="outlined" className="w-40">
                                        <InputLabel>Select Range</InputLabel>
                                        <Select label="Select Range" value={selectedRange} onChange={handleRangeChange}>
                                            <MenuItem value={1}>Current Week</MenuItem>
                                            <MenuItem value={2}>Month</MenuItem>
                                            <MenuItem value={3}>Year</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {showSecondDropdown && (
                                        <FormControl variant="outlined" className="w-40">
                                            <InputLabel>Choose</InputLabel>
                                            <Select label="Select" onChange={handleRangeOptions}>
                                                {secondDropdownOptions}
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>
                            </div>
                            <div className="overflow-auto h-full">
                                <LabOrdersLineChart data={labOrderGraphDetails} />
                            </div>
                        </Paper>
                    </Grid>

                    {/* SLA Compliance */}
                    <Grid item xs={12} md={6}>
                        <Paper className="p-4 flex flex-col h-full">
                            <div className="flex justify-between items-center mb-4">
                                <Typography variant="h6" align="left">
                                    SLA Compliance
                                </Typography>
                                <div className="flex items-center space-x-4">
                                    <FormControl variant="outlined" className="w-40">
                                        <InputLabel>Select Range</InputLabel>
                                        <Select label="Select Range" value={selectedSLARange} onChange={handleSLARangeChange}>
                                            <MenuItem value={1}>Current Week</MenuItem>
                                            <MenuItem value={2}>Month</MenuItem>
                                            <MenuItem value={3}>Year</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {showSecondSLADropdown && (
                                        <FormControl variant="outlined" className="w-40">
                                            <InputLabel>Choose</InputLabel>
                                            <Select label="Select" onChange={handleSLARangeOption}>
                                                {secondSLADropdownOptions}
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>
                            </div>
                            <div className="overflow-auto h-full">
                                <SLAComplianceBarChart data={slaComplianceGraphDetails} />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', marginBottom: '1rem' }}>
                <div style={{ display: 'flex' }} >
                    <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
                        Orders Received Today
                    </Typography>
                    {/* <Typography style={{ color: '#FFFFFF', background: '#42BA85', padding: '5px', borderRadius: '3.5px', width: '1.75rem', height: '2rem', marginLeft: '0.5rem' }}>
                        {count.receivedOrder}
                    </Typography> */}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="text" 
                    // onClick={() => navigate(APP_ROUTES.RECEIVEDORDERS)} 
                    style={{ color: '#3D65FF' }}>See All Orders</Button>
                </div>
            </div>
            <Box sx={{ minHeight: '50vh', maxHeight: '80vh', overflowY: 'auto', position: 'relative' }}>
                {receivedOrdersLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <div className="flex flex-col justify-center w-full">
                    {getDataForPage(page).map((data, index) => (
                        <Card key={index}>
                            <CardHeader
                                title={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <div>{`${data?.patientSalutation ? data?.patientSalutation + ' ' : ''}${data?.patientName}`}</div>
                                        <div>{`Patient Id: ${data?.patientId}`}</div>
                                    </div>
                                }
                                avatar={
                                    <div
                                        style={{
                                            width: 50,
                                            height: 50,
                                            border: '1px solid #ccc',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: 10,
                                            marginRight: 10,
                                            background: 'white',
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ color: '#3D65FF' }}>
                                            {data?.patientName ? data?.patientName.charAt(0).toUpperCase() : 'U'}
                                        </Typography>
                                    </div>
                                }
                            />
                            <CardContent className="flex">
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Order Id</span>: <span>{data?.orderId}</span>
                                        </Typography>

                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">eClinic Name</span>: <span>{data?.chehospitalName}</span>
                                        </Typography>

                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">eClinic Location</span>: <span>{data?.chelocationName}</span>
                                        </Typography>

                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Requested Date & Time</span>: <span>{formateDateToDDMMYYYYHHMM(data?.collectionSampleDate)}</span>
                                        </Typography>

                                        <Typography className="pr-8" variant="body1">
                                            <span className="font-semibold">Mobile</span>: <span>{data?.patientMobile}</span>
                                        </Typography>
                                    </div>

                                    <div className="flex flex-row mt-6">
                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Address</span>: <span>{data?.deliveryFullAddress}</span>
                                        </Typography>

                                        <Typography className="pr-8 border-r border-gray-400" style={{ marginRight: '2rem' }} variant="body1">
                                            <span className="font-semibold">Lab Test Name</span>:
                                            <span>
                                                {' '}
                                                {data?.patientLabTestsOrderDetailsList?.map((labTest, index) => (
                                                    <React.Fragment key={index}>
                                                        {labTest.labTestName}
                                                        {index < data.patientLabTestsOrderDetailsList.length - 1 && ', '}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </Typography>

                                        <Typography variant="body1">
                                            <span style={{ fontWeight: 600 }}>Sample Collection Address</span>: <span style={{ fontWeight: 400 }}>{data?.homeSamplePatientAddress}</span>
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>

                            <CardContent>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <Typography variant="body2" color="textSecondary" component="div">
                                        <Typography paragraph>Enter Details</Typography>
                                    </Typography>
                                    <CardActions disableSpacing>
                                        <IconButton
                                            aria-expanded={expanded === index}
                                            onClick={() => handleExpandClick(index, data)}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                </div>
                            </CardContent>

                            <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                <CardContent style={{
                                    marginTop: '-2rem'
                                }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={3}>
                                            <CustomDateTimePicker
                                                variant="outlined"
                                                fullWidth
                                                label="Sample Collection Date & Time"
                                                selectedDateTime={formData[index]?.homeSampleCollectionDateTime}
                                                defaultDateTime={data?.preferDateTime ? dayjs(data?.preferDateTime) : null}
                                                onChange={(dateTime) =>
                                                    handleFieldChange(index, 'homeSampleCollectionDateTime', dateTime)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomDateTimePicker
                                                variant="outlined"
                                                fullWidth
                                                label="Test at Lab Date & Time"
                                                selectedDateTime={formData[index]?.labTestDateTime}
                                                defaultDateTime={data?.collectionSampleDate ? dayjs(data?.collectionSampleDate) : null}
                                                onChange={(dateTime) =>
                                                    handleFieldChange(index, 'labTestDateTime', dateTime)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                label="Technicians Assigned"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={formData[index]?.techniciansAssigned || ''}
                                                onChange={(e) => handleFieldChange(index, 'techniciansAssigned', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                label="Contact Number"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                type="text"
                                                value={formData[index]?.contactNumber || ''}
                                                onChange={(e) => handleFieldChange(index, 'contactNumber', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel size="small" >Status</InputLabel>
                                                <Select
                                                    label="Status"
                                                    size="small"
                                                    value={formData[index]?.status !== undefined ? formData[index]?.status : ''}
                                                    onChange={(e) => handleFieldChange(index, 'status', e.target.value)}
                                                >
                                                    <MenuItem value="1">Order Confirmed</MenuItem>
                                                    <MenuItem value="3">Technician Assigned</MenuItem>
                                                    <MenuItem value="4">Sample Collected</MenuItem>
                                                    <MenuItem value="5">Cancelled by Patient</MenuItem>
                                                    <MenuItem value="6">Cancelled by Diagnostic</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Button variant="contained" color="primary" style={{ margin: '1rem' }} onClick={() => confirmOrder(data)}>
                                            OK
                                        </Button>
                                    </Grid>
                                </CardContent>
                            </Collapse>

                        </Card>
                    ))}
                </div>
                {orderDetails?.length > 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Pagination
                            count={Math.ceil(orderDetails.length / itemsPerPage)}
                            page={page}
                            color="secondary"
                            onChange={handlePageChange}
                        />
                    </Box>
                )}
            </Box>
            <div>
            </div>

        </>
    );
};

export default Dashboard;
